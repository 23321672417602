import React, { useContext } from 'react';
import styled from "styled-components";
import { UIContext } from './UIContext';
import PostlistEN from "./PostlistEN";
import PostlistDE from "./PostlistDE";
import PostlistDA from "./PostlistDA";
import PostlistSE from "./PostlistSE";
import PostlistNO from "./PostlistNO";
import PostlistNL from "./PostlistNL";
import PostlistFI from "./PostlistFI";
import PostlistFR from "./PostlistFR";
import SocialFollow from "./Socialfollow";

const StyledMainRightColumn = styled.div`
  grid-area: right;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
`;

const MainRightColumn = () => {
  const { language } = useContext(UIContext);

  const renderPostList = () => {
    const genre = "seo"; // You can make this dynamic if needed
    switch (language) {
      case "de":
        return <PostlistDE language={language} genre={genre} />;
      case "da":
        return <PostlistDA language={language} genre={genre} />;
      case "se":
        return <PostlistSE language={language} genre={genre} />;
      case "no":
        return <PostlistNO language={language} genre={genre} />;
      case "nl":
        return <PostlistNL language={language} genre={genre} />;
      case "fi":
        return <PostlistFI language={language} genre={genre} />;
      case "fr":
        return <PostlistFR language={language} genre={genre} />;
      default:
        return <PostlistEN language={language} genre={genre} />;
    }
  };

  return (
    <StyledMainRightColumn className="main-right">
      {renderPostList()}
      <SocialFollow language={language} />
    </StyledMainRightColumn>
  );
};

export default React.memo(MainRightColumn);