import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// 1. define Hreflang data, for href tags and language button
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/seo"
);
  

  const Seo = ({ location }) => { // Receive location as a prop
    return (
      <Layout location={location} alternateLangs={alternateLangs}>
        <React.Fragment>
          {/* 2. define SEO meta data */}
          <SEO
            title="Tutoriels sur le SEO & outils SEO"
            lang="fr"
            description="Améliorez vos compétences en SEO avec des tutoriels sur le SEO et des outils SEO DIY. Maîtrisez la recherche de mots-clés, l'optimisation sur la page et plus encore avec nos guides étape par étape."
            image="homepage-screenshot"
            alternateLangs={alternateLangs}
          // canonical="/fr"
          />
          <MainFrontpage>
            <SeoLeftColumn />
            <SeoRightColumn />
          </MainFrontpage>
        </React.Fragment>
      </Layout>
    );
  };
  
  export default React.memo(Seo);
  