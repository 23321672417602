import React, { useContext } from 'react';
import styled from "styled-components";
import { UIContext } from './UIContext';
import { Link } from "gatsby";
import Banner from "./Banner";
import Multilinks from "./Multilinks";
import CtaPrimary from "./CtaPrimary";
import Img from "./Img";
import RatingWidget from "./RatingWidget";

const StyledMainLeftColumn = styled.div`
  grid-area: left;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  .card:first-child {
    padding-bottom: 0;
  }
`;

// "Master Your Market with Elite SEO Services"
// "Elevate Your Online Presence with Expert SEO"
// "Unlock the Power of Top SEO Performance"
// "Drive Success with Strategic SEO Solutions"
// "Achieve Superior Search Engine Rankings"
// "Optimize Your Visibility with Proven SEO Techniques"
// "Transform Your Traffic with Advanced SEO"
// "Lead the Search Landscape with Custom SEO"
// "Dominate Your Niche with Targeted SEO"
// "Enhance Your Digital Strategy with Professional SEO"

const bannerHeadline = {
  en: "“Master your Niche with advanced SEO Strategies”",
  de: "“Meistere deine Nische mit überlegenen SEO-Methoden”",
  da: "“Mestre din Niche med avancerede SEO-strategier”",
  se: "“Bemästra din Nisch med avancerade SEO-strategier”",
  no: "“Mestre din Niche med avanserte SEO-strategier”",
  nl: "“Beheers je Niche met geavanceerde SEO-strategieën”",
  fi: "“Hallitse Nicheasi edistyneillä SEO-strategioilla”",
  fr: "“Maîtrisez votre Niche avec des Stratégies SEO Avancées”"
};

const bannerCta = {
  en: "Services",
  de: "Leistungen",
  da: "Tjenester",
  se: "Tjänster",
  no: "Tjenester",
  nl: "Diensten",
  fi: "Palvelut",
  fr: "Services"
};

const serviceLinks = {
  en: "/en/services",
  de: "/de/leistungen",
  da: "/da/services",
  fi: "/fi/palvelut",
  fr: "/fr/les-services",
  nl: "/nl/diensten",
  no: "/no/tjenester",
  se: "/se/tjänster",
  x_default: "/en/services"
};

const MainLeftColumn = () => {
  const { language } = useContext(UIContext); // Destructured for direct access
  const lang = language || "en"; // Fallback to "en" if language is not defined

  // Determine the correct link, falling back to x-default if not found
  const serviceLink = serviceLinks[lang] || serviceLinks.x_default;

  return (
    <StyledMainLeftColumn className="main-left">
      <Banner>
        <h1 className="banner__headline text headline">{bannerHeadline[lang]}</h1>
        <div className="banner__image">
          <Img clickable="false" src="about/matthias-kupperschmidt-frontal-collar-transparent-square.png" alt="matthias kupperschmidt portrait" />
        </div>

        <div className="features">
          <Link className="cta-primary" to={serviceLink}>
            <CtaPrimary color="red" className="cta-primary--align">{bannerCta[lang]}</CtaPrimary>
          </Link>
          <RatingWidget className="rating" lang={language} />
        </div>
      </Banner>
      {/* genre can be 'front', 'analytics' or 'seo' */}
      <Multilinks language={lang} genre={'seo'} />
    </StyledMainLeftColumn>
  );
};

export default React.memo(MainLeftColumn);

